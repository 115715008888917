import React from 'react';
import Works from './WorksList.json';
import { Link } from 'react-router-dom';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LazyLoad from 'react-lazy-load';

const Obras = () => {
    return (
        <div className="flex-col w-full md:flex align-center my-[120px] lg:pl-[110px] lg:pr-[70px] px-[55px] h-full">
            <div className='flex mb-[50px] items-center'>
                <div className='bg-[#C6161D] sm:h-[60px] w-[15px] h-[100px] mr-3' />
                <h1 className='font-bold text-2xl lg:text-3xl tracking-widest uppercase'>conocé nuestras obras</h1>
            </div>
            <div>
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            </div>
            <div className='grid md:grid-cols-2 grid-cols-1'>
                {Works && Works.map((work) => (
                    <div key={work.id} className="lg:flex">
                        <LazyLoad width={"100%"} debounce={false} offsetVertical={200}>
                            <div className='flex justify-center'>
                                <img src={work.image} alt="imagen" className='2xl:h-[300px] 2xl:max-w-[300px] md:h-[200px] md:max-w-[200px] object-scale-down mb-5 mr-10 aspect-square' />
                            </div>
                        </LazyLoad>
                        <div key={work.id} className="grid grid-cols-1 content-between w-full 2xl:h-[300px] xl:h-[200px] md:h-[200px] lg:mb-8 mb-5 mr-8">
                            <div>
                                {work.state === 'En Ejecución' && <p className='uppercase font-bold text-orange-500'>{<AccessTimeRoundedIcon />} {work.state}</p>}
                                {work.state === 'Finalizado' && <p className='uppercase font-bold text-green-500'>{<CheckBoxOutlinedIcon />} {work.state}</p>}
                                {work.state === 'Proximamente' && <p className='uppercase font-bold text-red-500'>{<CalendarTodayOutlinedIcon />} {work.state}</p>}
                                <hr className="my-2 mx-auto w-full h-1 bg-gray-300 rounded border-0" />
                                <p className='uppercase font-bold 2xl:text-xl md:text-sm text-xl mb-1'>{work.title}</p>
                                {work.description.map((desc) => (
                                    <p key={desc} className="2xl:text-base text-sm">{desc}</p>
                                ))}
                            </div>
                            <div>
                                <Link className='uppercase text-base' to={`/ObrasInfo/${work.title}`}>{<AddCircleRoundedIcon />} Ver Más</Link>
                                <hr className="my-2 mx-auto w-full h-1 bg-gray-300 rounded border-0 lg:hidden" />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            <img src='../images/Torres.png' alt='Torres' className="w-full" />
        </div>
    )
}

export default Obras
