import { React, useEffect } from "react";

function Home(setShowNav) {

    useEffect(() => {
        setShowNav.setShowNav(true);
        // eslint-disable-next-line
    }, []);

    return (

        <div className='mt-16 md:mt-[120px] md:pl-[90px] md:pr-[55px] w-full h-full'>
            <div className="w-full md:flex justify-center align-center mb-10">
                <div className='md:shrink-0 mx-auto '>
                    <img className='md:h-auto xl:w-[750px] md:w-[620px] sm:ml-[30px] shadow-lg' src="../images/EXPO.png" alt="" />
                </div>

                <div className='p-4 text-center flex flex-col xl:w-[480px] sm:w-[400px] m-auto'>
                    <div className='flex'>
                        <div className='bg-[#C6161D] w-3 mr-3' />
                        <div className='font uppercase text-xl xl:text-2xl font-semibold leading-6 tracking-widest'>sobre nosotros</div>
                    </div>
                    <hr className=" my-6 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:mt-11" />
                    <p className='text-justify leading-4 xl:text-sm sm:text-xs text-sm font-medium'>
                        Somos una empresa dedicada al desarrollo de negocios inmobiliarios con más de 16
                        años de experiencia en la construcción de emprendimientos rentables.
                        Nos caracterizamos por la transparencia y la confianza para nuestros inversores.
                        Constantemente estamos en la búsqueda de nuevas tecnologías y materiales de diseño e innovación. Seleccionamos las mejores ubicaciones y costos para poder garantizar las mejores inversiones. Nos dedicamos tanto a la arquitectura como al interiorismo brindando un producto final de mayor categoría.
                    </p>
                    <hr className="xl:mb-14 md:mb-10 mb-1 mt-4 mx-auto w-full h-1 bg-gray-300 border-0 rounded" />
                    <img src='../images/POLAK.svg' alt='' />
                </div>
            </div>
            <img src='../images/Torres.png' alt='Torres' className="w-full" />
        </div>
    );
}

export default Home;

