import { React, useState } from "react"
import { Link } from 'react-router-dom'
import DehazeIcon from '@mui/icons-material/Dehaze';

function NavBar() {
    const [toggle, setToggle] = useState(false);

    return (
        <nav className={`w-full xl:py-[55px] py-[45px] bg-[#EEEEEE]  sticky lg:relative lg:mt-0 shadow-lg`}>
            <div className={`container mx-auto flex items-center xl:px-0`}>
                <Link className=" flex items-center sm:mx-2 mx-16" to='/'><img className="2xl:w-[400px] xl:w-[350px] w-64" src='../images/POLAK.svg' alt='' /></Link>
                <button className={`lg:hidden ${toggle ? '' : 'flex'}`} onClick={() => setToggle((prev) => !prev)}>{<DehazeIcon />}</button>
                <div className='container w-full hidden uppercase font text-black lg:flex lg:justify-around text-lg '>
                    <Link className='navLink mx-4' to='/home'>home</Link>
                    <Link className='navLink mx-4' to='/obras'>obras</Link>
                    <Link className='navLink mx-4' to='/constructora'>constructora</Link>
                    <Link className='navLink mx-4' to='/participaciones'>participaciones</Link>
                    <Link className='navLink mx-4' to='/prensa'>prensa</Link>
                    <Link className='navLink mx-4' to='/contacto'>contacto</Link>
                </div>
            </div>
            <div className={`container w-full lg:hidden uppercase font text-black text-lg mt-10 ml-14 ${toggle ? 'flex flex-col' : 'hidden'}`}>
                <Link className='navLink' to='/home' onClick={()=>setToggle(false)}>home</Link>
                <Link className='navLink' to='/obras' onClick={()=>setToggle(false)}>obras</Link>
                <Link className='navLink' to='/constructora' onClick={()=>setToggle(false)}>constructora</Link>
                <Link className='navLink' to='/participaciones' onClick={()=>setToggle(false)}>participaciones</Link>
                <Link className='navLink' to='/prensa' onClick={()=>setToggle(false)}>prensa</Link>
                <Link className='navLink' to='/contacto' onClick={()=>setToggle(false)}>contacto</Link>
            </div>
        </nav>
    )
}

export default NavBar