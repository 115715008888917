import React from "react";

const Contacto = () => {
    return (
        <div className="w-full h-screen max-h-[800px] bg-[#FFFFFF] md:flex justify-around items-center p-4">
            <div className="grid grid-cols-1 mb-[80px] mx-auto sm:mt-0 mt-[50px]">
                <div className='flex mb-[30px] items-center'>
                    <div className='bg-[#C6161D] w-[15px] sm:h-[53px] h-[76px] mr-3' />
                    <h1 className='font-bold  xl:text-3xl lg:text-2xl text-2xl tracking-widest uppercase'>contactanos</h1>
                </div>
                <div className="mx-auto">
                    <div className='flex mb-[20px] sm:ml-9 items-center'>
                        <img src="../images/WhatsApp.svg" alt="whatsapp" className="w-[25px]"/>
                        <p className="xl:text-2xl font-semibold ml-3">11 5427-7260</p>
                    </div>
                    <div className='flex sm:ml-9 items-center'>
                        <img src="../images/Secured Letter.svg" alt="whatsapp" className="w-[25px]"/>
                        <p className="xl:text-2xl font-semibold ml-3">jesica@polakambach.com</p>
                    </div>
                </div>
            </div>
            <div className="md:w-[700px] w-[300px] mx-auto">
                <form
                    action="https://getform.io/f/c64e18ba-0cf7-4da6-8930-8287d2dc8ac4"
                    method="POST"
                    className="flex flex-col md:max-w-[600px] max-w-[300px]"
                >
                    <p className="w-[192px] h-[29px] text-[#000000] xl:text-xl lg:text-base text-xl">Nombre y apellido</p>
                    <input
                        type="text"
                        placeholder="Tu nombre y apellido"
                        name="nombre"
                        className="bg-[#F4F4F4] mb-8 p-2 rounded-[29px]"
                    />
                    <p className="w-[46px] h-[29px] text-[#000000] xl:text-xl lg:text-base text-xl">Mail</p>
                    <input
                        type="text"
                        placeholder="ejemplo@gmail.com"
                        name="mail"
                        className="bg-[#F4F4F4] mb-8 p-2 rounded-[29px]"
                    />
                    <p className="w-[89px] h-[29px] text-[#000000] xl:text-xl lg:text-base text-xl">Mensaje</p>
                    <textarea
                        className="bg-[#F4F4F4] p-2 rounded-[29px]"
                        name="mensaje"
                        placeholder="Escribir"
                    />
                    <button className="bg-[#C6161D] text-white xl:text-xl lg:text-base text-xl font-bold xl:w-[180px] xl:h-[53px] lg:w-[150px] lg:h-[40px] w-[180px] h-[53px] my-8 mx-auto rounded-md">
                        Enviar
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Contacto;