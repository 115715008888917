import React from "react";
import Construction from './Construction.json'
import LazyLoad from 'react-lazy-load';

function Constructora() {


    return (
        <div className="flex flex-col md:flex mt-[120px] lg:pl-[110px] lg:pr-[70px] mb-24 mb:mb-52 h-full ">
            <div className="flex mb-[50px] ml-6">
                <div className="bg-[#C6161D] w-3 mr-3" />
                <div className="font uppercase text-2xl lg:text-3xl tracking-widest font-semibold">
                    trabajamos <br />en equipo
                </div>
            </div>
            <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            <div className=" lg:flex justify-center  items-center">
                <div className=" p-4 md:p-4 lg:px-5 flex flex-col md:mr-24 items-center max-w-[1100px]">
                    <img
                        className="w-[200px] 2xl:w-[270px] max-w-[1000px]"
                        src="./images/constructora/COCA LOGO.svg"
                        alt=""
                    />
                    <p className=" text-base xl:text-xl lg:text-lg text-justify leading-5 md:leading-6 mt-20 xl:mt-32 max-w-[450px]">
                        Es una constructora familiar, fundada por el MMO Damian Coca en el
                        año 2005. En sus inicios fue creada exclusivamente para la ejecución
                        de obras para terceros pero hoy nos centralizamos en la construcción
                        de nuestras propias obras.
                    </p>
                </div>
                <div>
                    <img
                        className="w-[770px] sm:pr-1"
                        src="./images/constructora/Coca Staff.jpg"
                        alt=""
                    />
                </div>
            </div>
            <hr className="my-8 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            <div className="px-4 grid grid-cols-1 lg:grid-cols-3 justify-items-center">
                {Construction.map((construction) => (
                    <div
                        key={construction.id}
                        className="flex justify-center 2xl:w-[390px] 2xl:h-[550px] xl:w-[290px] xl:h-[450px] w-[250px] h-[350px] relative mb-8"
                    >
                        <LazyLoad width={"100%"} debounce={false} offsetVertical={200}>
                            <img
                                className="2xl:w-[390px] 2xl:h-[550px] xl:w-[290px] xl:h-[450px] w-[250px] h-[350px] grayscale"
                                src={construction.img}
                                alt=""
                            />
                        </LazyLoad>
                        <div className="font text-2xl lg:text-4xl bg-black text-white flex items-center justify-center absolute inset-0 z-10 text-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300">
                            {construction.address}
                        </div>
                    </div>
                ))}
            </div>
            <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            <img src='../images/Torres.png' alt='Torres' className="w-full" />
        </div>
    );
}

export default Constructora;