import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className=' w-full h-[140px] p-6 bg-[#EEEEEE] flex flex-col lg:flex lg:justify-center items-center absolute borrom-0 shadow-inner'>
            <img className='w-64' src='../images/POLAK.svg' alt='logo'/>
            <div className='uppercase text-black flex items-center mt-3 sm:text-md lg:text-base font-semibold sm:right-7 lg:right-14 sm:absolute'>
                <img src="../images/Secured Letter.svg" alt="whatsapp"  className='md:mr-2 lg:mr-4 w-5 lg:w-6'/>
                <Link to='/contacto'>contacto</Link>
            </div>
        </div>
    )
}

export default Footer
