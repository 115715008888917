import React from 'react'

const Prensa = () => {
    return (
        <div className="flex-col w-full md:flex align-center my-[120px] sm:pl-[110px] sm:pr-[70px] px-[55px] h-full">
            <div className='flex mb-[50px] items-center'>
                <div className='bg-[#C6161D] w-[15px] h-[50px] mr-3' />
                <h1 className='font-bold text-2xl lg:text-3xl tracking-widest uppercase'>prensa</h1>
            </div>
            <div>
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            </div>
            <div className='flex flex-wrap justify-around'>
                <img className='object-contain 2xl:h-[680px] xl:h-[450px] sm:h-[170px]' src='../images/prensa/prensa-1.jpg' alt='Prensa Clarin_Zapiola 2351' />
                <img className='object-contain 2xl:h-[680px] xl:h-[450px] sm:h-[170px]' src='../images/prensa/prensa-8.jpg' alt='Jesi_Revista 1' />
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
                <img className='object-contain 2xl:h-[580px] xl:h-[380px] sm:h-[150px]' src='../images/prensa/prensa-9.jpg' alt='Jesi_Revista 2' />
                <img className='object-contain 2xl:h-[580px] xl:h-[380px] sm:h-[150px]' src='../images/prensa/prensa-7.JPG' alt='Expo conrad_Punta del Este_11-01-12' />
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
                <img className='object-contain 2xl:h-[580px] xl:h-[380px] sm:h-[150px]' src='../images/prensa/prensa-4.jpg' alt='Revista1_Jesi' />
                <img className='object-contain 2xl:h-[580px] xl:h-[380px] sm:h-[150px]' src='../images/prensa/prensa-5.jpg' alt='Revista2_Jesi' />
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
                <img className='object-contain 2xl:h-[750px] xl:h-[450px] sm:h-[170px]' src='../images/prensa/prensa-6.JPG' alt='2 Expo conrad_Punta del Este_11-01-12' />
                <img className='object-contain 2xl:h-[750px] xl:h-[450px] sm:h-[170px]' src='../images/prensa/prensa-2.jpg' alt='Real Estate 1' />
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
                <img className='object-contain 2xl:h-[720px] xl:h-[450px] sm:h-[170px]' src='../images/prensa/prensa-3.jpg' alt='Real Estate 4' />
                <img className='object-contain 2xl:h-[720px] xl:h-[450px] sm:h-[170px]' src='../images/prensa/prensa-10.JPG' alt='Jesi_Revista 3' />
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            </div>
            <img src='../images/Torres.png' alt='Torres' className="w-full" />
        </div>
    )
}

export default Prensa
