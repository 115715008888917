import React from 'react';
import Participations from './Participations.json';
import LazyLoad from 'react-lazy-load';

const Partcipaciones = () => {
    return (
        <div className="flex-col w-full md:flex align-center my-[120px] sm:pl-[110px] sm:pr-[70px] px-[55px] h-full">
            <div className='flex mb-[50px] items-center'>
                <div className='bg-[#C6161D] sm:h-[90px] w-[15px] h-[80px] mr-3' />
                <h1 className='font-bold text-base lg:text-3xl tracking-widest uppercase'>proyectos en los que <br />participamos</h1>
            </div>
            <div>
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            </div>
            <div className='grid md:grid-cols-3 grid-cols-1'>
                {Participations && Participations.map((part) => (
                    <div key={part.id} className='flex justify-center 2xl:h-[400px] h-[250px] aspect-square relative mb-8 lg:ml-12 mx-auto'>
                        <LazyLoad width={"100%"} debounce={false} offsetVertical={200}>
                            <img src={part.image} alt="imagen" className='2xl:h-[400px] h-[250px] aspect-square mb-10 grayscale' />
                        </LazyLoad>
                        <div className='absolute lg:w-[285px] lg:ml-[58px] w-[180px] ml-[35px] inset-0 z-10 bg-black text-white text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-90 duration-300'>
                            <p>{part.title}</p><br />
                            {part.description.map((desc) => (
                                <p key={desc}>{desc}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:my-16" />
            <img src='../images/Torres.png' alt='Torres' className="w-full" />
        </div>
    )
}

export default Partcipaciones
