import React, { useState } from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './components/Home';
import NavBar from './components/NavBar';
import Index from './components/Index';
import Prensa from './components/Prensa';
import Obras from "./components/Obras";
import ObrasInfo from './components/ObrasInfo';
import Partcipaciones from './components/Partcipaciones';
import Contacto from './components/Contacto';
import Constructora from './components/Constructora';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [showNav, setShowNav] = useState(true);
  return (
    <div>
      {
        showNav && <NavBar />
      }
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Index setShowNav={setShowNav} />} />
        <Route path="/home" element={<Home setShowNav={setShowNav} />} />
        <Route path="/obras" element={<Obras />} />
        <Route path="/obrasInfo/:title" element={<ObrasInfo />} />
        <Route path="/participaciones" element={<Partcipaciones />} />
        <Route path="/constructora" element={<Constructora />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/prensa" element={<Prensa />} />
      </Routes>
      {
        showNav && <Footer />
      }
    </div>
  );
}

export default App;
