import { React, useEffect } from 'react'
import { Link } from 'react-router-dom'
import videoBg from './assets/videoBg.webm'

const Index = (setShowNav) => {

    useEffect(() => {
        setShowNav.setShowNav(false);
        // eslint-disable-next-line
    }, []);

    return (
        <div className='w-full h-screen'>

            <video src={videoBg} autoPlay loop muted
                className='w-full h-full object-cover'
            />
            <div className='flex flex-col justify-center items-center w-full lg:h-1/3 h-2/5 bg-white bg-opacity-80 top-1/3 absolute'>
                <img className='2xl:w-[700px] lg:w-[500px] md:w-[400px] w-[300px]' src='../images/POLAK.svg' alt='' />
                <Link className='bg-transparent xl:px-4 px-2 xl:py-1 py-0.5 xl:mt-5 mt-2 hover:bg-[#C6161D] text-[#C6161D] hover:text-white border-2 border-[#C6161D]
                 hover:border-transparent lg:text-2xl text-sm' to='/home'>INGRESAR</Link>
            </div>
        </div>
    )
}

export default Index
