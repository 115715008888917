import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Works from './Works.json';
import LazyLoad from 'react-lazy-load';

const ObrasInfo = () => {
    const { title } = useParams();
    let navigate = useNavigate();

    let goBack = () => {
        navigate(-1);
    };

    return (
        <div className="flex-col w-full md:flex align-center mt-[120px] sm:px-[70px] px-[20px] h-full mx-auto">
            <div className='mx-auto md:mb-8 w-full '>
                <hr className="mb-4 h-1 bg-gray-300 rounded border-0 md:mb-8" />
                <div className='flex justify-items-center items-center'>
                    <div className='bg-[#C6161D] sm:w-[15px] w-10 mr-3 sm:h-[50px] h-[120px]' />
                    <h1 className='font-bold text-xl lg:text-2xl tracking-widest xl:w-full lg:w-[750px] md:w-[550px] w-[400px]'>{title}</h1>
                    {
                        Works && Works.map((work) => (
                            <div key={work.id}>
                                {
                                    work.title === title ?
                                        work.state === 'En Ejecución' ? <img src='../images/coming soon.png' alt='coming soon' className='2xl:w-[350px] lg:w-[310px] w-[220px] absolute xl:top-[280px] lg:top-[246px] md:top-[280px] top-[265px] right-[60px]' /> :
                                            work.state === 'Finalizado' ? <img src='../images/finished.png' alt='finished' className='xl:w-[220px] lg:w-[190px] md:w-[170px] w-[110px] absolute xl:top-[250px] lg:top-[225px] md:top-[250px] top-[260px] right-[80px]' /> :
                                                work.state === 'Proximamente' ? <img src='../images/work in progress.png' alt='work in progress' className='lg:w-[300px] md:w-[230px] w-[200px] absolute 2xl:top-[285px] xl:top-[275px] lg:top-[250px] md:top-[270px] top-[265px] right-[60px]' /> :
                                                    "" :
                                        ""
                                }
                            </div>
                        ))}
                </div>
                <hr className="my-4 h-1 bg-gray-300 rounded border-0 md:my-8" />
            </div>
            <div>
                {
                    Works && Works.map((work) => (
                        work.title === title ?
                            work.state ?
                                <div key={work.id} className='grid justify-items-center'>
                                    <div className='flex mb-10'>
                                        <img src={work.image} alt="imagen" className='sm:w-full  w-[800px] lg:mb-10' />
                                    </div>
                                    <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:mb-6" />
                                    <div className='flex justify-between'>
                                        <img src='../images/Double Left.svg' alt='double arrow' className='rotate-180 w-[50px]' />
                                        <div className='grid justify-items-center'>
                                            {work.description.map((desc) => (
                                                <p key={desc} className='2xl:mx-[500px] lg:mx-[300px] mx-auto '>{desc}</p>
                                            ))}
                                        </div>
                                        <img src='../images/Double Left.svg' alt='double arrow' className='w-[50px]' />
                                    </div>
                                    <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:mb-16" />
                                </div> :
                                "" :
                            ""
                    ))}
            </div>
            <div className={`grid grid-cols-1 justify-items-center`}>
                {
                    Works && Works.map((work) => (
                        work.title === title ?
                            work.state ? '' :
                                <div key={work.id} className='flex mb-10'>
                                    <LazyLoad width={"100%"} debounce={false} offsetVertical={200}>
                                        <img src={work.image} alt="imagen" className='sm:w-full w-[800px] lg:mb-10 ' />
                                    </LazyLoad>
                                </div>
                            : ''
                    ))}
            </div>
            <div>
                <hr className="my-4 mx-auto w-full h-1 bg-gray-300 rounded border-0 md:mb-16" />
            </div>
            <div className='grid grid-cols-1 lg:mb-10'>
                <div className='flex justify-center items-center'>
                    <img src='../images/Place Marker.svg' alt='Place Marker' className='sm:h-[70px] h-[50px] mt-5' />
                    <h1 className='uppercase 2xl:text-4xl text-2xl font-bold ml-6 mt-5'>ubicacion</h1>
                </div>
                <div className='flex justify-center my-[20px]'>
                    {
                        Works && Works.map((work) => (
                            work.title === title ?
                                work.state ?
                                    work.location ? <img key={work.id} src={work.location} alt="imagen" className='sm:w-[1000px]  w-[800px] lg:mb-10' /> :
                                        "" :
                                    "" :
                                ""
                        ))}
                </div>
                <div className='lg:flex lg:flex-row flex-col justify-center my-16 lg:ml-10'>
                    <img src='../images/Torres.png' alt='buildings' className='w-[635px] 2xl:mx-[400px] mx-auto object-cover' />
                    <button
                        onClick={goBack}
                        className='grid grid-cols-1 my-4 lg:mx-0 mx-auto'>
                        <div>
                            <img src='../images/Back Arrow.png' alt='back arrow'
                                className='sm:w-[75px] sm:ml-0 ml-1 w-[50px]'
                            />
                            <p className='uppercase sm:text-xl text-base font-semibold'>volver</p>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ObrasInfo
